var nav = document.getElementById('navBar'),
    iss = document.getElementById('mobileNav'),
    c1 = document.getElementsByTagName('div')[2],
    c2 = document.getElementsByTagName('div')[3],
    c3 = document.getElementsByTagName('div')[4];
    if (iss) {
        iss.addEventListener('click', function() {
            if (nav.className.match(/\bopen\b/)) {
                nav.classList.remove('open');
                c1.classList.remove('close-c1');
                c2.classList.remove('close-c2');
                c3.classList.remove('close-c3');
            } else {
                nav.classList.add('open');
                c1.classList.add('close-c1');
                c2.classList.add('close-c2');
                c3.classList.add('close-c3');
            }
        });
    }
    if (nav) {
        nav.addEventListener('click', function() {
            nav.classList.remove('open');
            c1.classList.remove('close-c1');
            c2.classList.remove('close-c2');
            c3.classList.remove('close-c3');
        });
    }

function openSearch() {
    document.getElementById("overlaySearch").style.height = "50%";
}

/* Close */
function closeSearch() {
    document.getElementById("overlaySearch").style.height = "0%";
}
// function toggleChat() {
//     $("#chatBox").fadeIn(300);
//     $(".chat-icon img").attr("src", "/Assets/Images/Chat-icons/Chat-close.png");
// }

// function openBigChat() {
//     $("#chatBox").fadeOut(50);
// }
// /* Close */

var socket = io(`http://${host}/messages`);
var rawRegisteration = $('input[name=registeration]').val();
var registeration = null;

if (rawRegisteration.length > 0)
    registeration = JSON.parse($('input[name=registeration]').val());

if (sid) {
    socket.on('connect', function() {
        socket.emit('connectFromClient', {
            sid: sid
        });
    });

    $(window).on("unload", function(e) {
        socket.emit('disconnectFromClient', {
            sid: sid
        });
    });
    // $(window).unload(function() {
    //     socket.emit('disconnectFromClient', {
    //         sid: sid
    //     });
    // })

    socket.on('disconnect', function() {
        socket.emit('disconnectFromClient', {
            sid: sid
        });
    });
}

socket.on('submitInfoSuccessfully', function(data) {
    registeration = data;
    $(".chat-info").fadeOut(200);
    socket.emit('initChat', registeration);
    $(".chat-conversation").fadeIn(300);
});

socket.on('messageFromClientSuccessfully', function(message) {
    if (message.fromClient.sid === sid) {
        var htmlString =
        `
            <div class="message"> 
                <div class="content my">${message.content}</div>
            </div>
        `;
        // <div class="time">${moment(message.createdAt).format("DD")} Tháng ${moment(message.createdAt).format("MM")}</div>     
        $("#messageBox").append(htmlString);
        $('#messageBox').animate({ scrollTop: $('#messageBox').prop('scrollHeight')}, 200);
        $("input[name=content]").val('');
    }
});

socket.on('messageFromAdminSuccessfully', function(message) {
    if (message.toClient.sid === sid) {
        var htmlString =
        `
            <div class="message"> 
                <div class="soc-icon"><img src='/Assets/Images/Chat-icons/soc-icon.png' alt='soc-icon'/></div>
                <div class="content">${message.content}</div>
            </div>
        `;
        // <div class="time">${moment(message.createdAt).format("DD")} Tháng ${moment(message.createdAt).format("MM")}</div>     
        $("#messageBox").append(htmlString);
        $('#messageBox').animate({ scrollTop: $('#messageBox').prop('scrollHeight')}, 200);
        $("input[name=content]").val('');
    }
});

$("#infoForm").submit(function(event) {
    event.preventDefault();
    const data = $(this).serializeArray();
    const fullName = data.find(d => d.name === 'name').value;
    const email = data.find(d => d.name === 'email').value;
    const phoneNumber = data.find(d => d.name === 'phoneNumber').value;

    socket.emit('submitInfo', {
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber,
        sid: sid
    });
});

$("#chatForm").submit(function(event) {
    event.preventDefault();
    const content = $(this).serializeArray().find(c => c.name === 'content').value;
    socket.emit('messageFromClient', {
        content: content,
        sid: sid
    });
});

function chatInfo() {
    $(".chat-welcome").fadeOut(200);

    if (registeration) {
        socket.emit('initChat', registeration);
        $(".chat-conversation").fadeIn(300);
    }
    else {
        $(".chat-info").fadeIn(300);
    }
}

function openConversation() {
    event.preventDefault();
    $(".chat-info").fadeOut(200);
    $(".chat-conversation").fadeIn(300);    
}
function backInfo() {
    $(".chat-conversation").fadeOut(200);
    $(".chat-info").fadeIn(300);    
}

$("#attachFile").click(function() {
    $("input[name=file]").click();
});

$("input[name=file]").on("change", function (event) {
    var fullPath = $("input[name=file]").val();
    var filename = null;
    if (fullPath) {
        var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
        filename = fullPath.substring(startIndex);
        if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
            filename = filename.substring(1);
        }
    }
    
    var form_data = new FormData();
    form_data.append('file', $("input[name=file]")[0].files[0]);
    $.ajax({
        type: 'POST',
        url: '/chat_ajax/upload/',
        processData: false,
        contentType: false,
        async: false,
        cache: false,
        data : form_data,
        success: function(response){
            if (response.status) {
                socket.emit('messageFromClient', {
                    content: `<a href='${response.imagePath}' target='_blank'>${filename}</a>`,
                    sid: registeration.sid
                });
            }
        }
    });
});

$(document).ready(function(){
    $(".chat-icon").click(function(){
        if ($("#chatBox").is(":visible")) {
            $("#chatBox").fadeOut(50);
            $(".chat-icon img").attr("src", "/Assets/Images/Chat-icons/Chat1.png");               
        }
        else {
            $("#chatBox").fadeIn(300);
            $(".chat-icon img").attr("src", "/Assets/Images/Chat-icons/Close1.png");   
        }
    });

    $('#messageBox').animate({ scrollTop: $('#messageBox').prop('scrollHeight')}, 200);

    $(".select2").select2({
        width: '240px'
    });

    $('.select2').on('change', function (e) {
        location.href = $(this).val();
    });

    $('a.search-icon').click(function() {
        location.href = $('.select2').val();
    });

    $(".js-scroll-footer").click(function() {
        $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    });
})
